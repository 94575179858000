import React, { useCallback, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  IconAlertTriangle,
  IconBookmark,
  IconTrash,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { cloneDeep, isEqual } from 'lodash';
import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import {
  Button,
  ErrorText,
  FormField,
  Label,
  Loader,
  Surface,
  Switch,
} from '@noloco/components';
import { OUTLINE } from '@noloco/components/src/components/button/buttonTypes';
import HelpTooltip from '@noloco/components/src/components/popover/HelpTooltip';
import { DARK, LIGHT } from '@noloco/components/src/constants/surface';
import { LG, MD, SM, XS } from '@noloco/components/src/constants/tShirtSizes';
import { FILE } from '@noloco/core/src/constants/builtInDataTypes';
import { AIRTABLE, INTERNAL } from '@noloco/core/src/constants/dataSources';
import baseDataTypes, {
  BOOLEAN,
  DATE,
  DURATION,
  DataFieldType,
  FORMULA,
  LOOKUP,
  MULTIPLE_OPTION,
  NUMERIC_DATATYPES,
  OBJECT,
  ROLLUP,
  SINGLE_OPTION,
  TEXT,
} from '@noloco/core/src/constants/dataTypes';
import {
  VALID_FORMULA_TYPES_TO_CONVERT,
  VALID_TYPES_TO_CONVERT,
} from '@noloco/core/src/constants/fieldConversion';
import {
  OBJECT_FORMATS,
  PHONE_NUMBER,
} from '@noloco/core/src/constants/fieldFormats';
import { SUB_FIELD_CONFIG } from '@noloco/core/src/constants/objects';
import {
  DataField,
  DataFieldOption,
  FieldTypeOptions,
} from '@noloco/core/src/models/DataTypeFields';
import DataTypes, { DataType } from '@noloco/core/src/models/DataTypes';
import { updateDataType as updateDataTypeState } from '@noloco/core/src/reducers/project';
import {
  DATA_FIELD_UPDATED,
  NEW_DATA_FIELD_SAVED,
  NEW_LOOKUP_FIELD_SAVED,
  NEW_ROLLUP_FIELD_SAVED,
  trackEvent,
} from '@noloco/core/src/utils/analytics';
import { addRelatedFieldsToDataType } from '@noloco/core/src/utils/data';
import { getValueForFieldTypeInput } from '@noloco/core/src/utils/dataTypes';
import { getValidTypeOptionsForFieldSource } from '@noloco/core/src/utils/fieldTypeOptions';
import { allowNegative, sortOptions } from '@noloco/core/src/utils/fields';
import { isFormulaValid } from '@noloco/core/src/utils/formulas/isFormulaValid';
import { useGraphQlErrorAlert } from '@noloco/core/src/utils/hooks/useAlerts';
import useHasFeatureFlag, {
  LOOKUP_FIELDS,
} from '@noloco/core/src/utils/hooks/useHasFeatureFlag';
import { useMutableFieldProperties } from '@noloco/core/src/utils/hooks/useMutableFieldProperties';
import { getText } from '@noloco/core/src/utils/lang';
import { isOptionType, isOptionValid } from '@noloco/core/src/utils/options';
import {
  getRelationshipFromMultiOpts,
  isMultiField,
  isReverseMultiField,
} from '@noloco/core/src/utils/relationships';
import sampleFieldNames from '../../../constants/sampleFieldNames';
import {
  ADD_DATA_FIELD,
  ADD_DATA_LOOKUP,
  ADD_DATA_ROLLUP,
  UPDATE_DATA_TYPE,
  UPSERT_DATA_FIELD,
} from '../../../queries/project';
import {
  useFieldNameValidation,
  useReverseFieldNameValidation,
} from '../../../utils/hooks/useDataTypeNameValidation';
import {
  useAddDataField,
  useUpdateDataField, // useUpdateDataField,
} from '../../../utils/hooks/useUpdateDataTypes';
import RightPopoutMenu from '../../canvas/RightPopoutMenu';
import FieldVariableNameDetails from '../../dataTable/FieldVariableNameDetails';
import ChangeFieldTypeWarningModal from './ChangeFieldTypeWarningModal';
import DeleteFieldModal from './DeleteFieldModal';
import FieldOptionsInput, { cleanOptions } from './FieldOptionsInput';
import FieldTypeInput from './FieldTypeInput';
import FieldTypeOptionsEditor, {
  FIELDS_WITH_TYPE_OPTIONS,
} from './FieldTypeOptionsInput';
import LookupEditor from './LookupEditor';
import OptionEditor from './OptionEditor';
import RelationshipEditor from './RelationshipEditor';
import RollupEditor from './RollupEditor';

export type UpsertedOption = Partial<Omit<DataFieldOption, 'id'>> & {
  display: string;
  order: number;
  id: string | number; //if is termporal ID, it's a uuid string
};
const sampleFieldNameOptions = sampleFieldNames.map((value) => ({
  value,
  label: value,
}));

const NON_UNIQUE_DATA_TYPES = [
  BOOLEAN,
  DATE,
  SINGLE_OPTION,
  MULTIPLE_OPTION,
  DURATION,
];

const getDefaultFormValues = (value: Partial<DataField> = {}) => ({
  id: value.id ?? null,
  displayName: value.display ?? '',
  type: (value.type ?? null) as string | null,
  isMulti: value.type ? isMultiField(value as DataField) : false,
  isReverseMulti: value.type ? isReverseMultiField(value as DataField) : true,
  unique: value.unique ?? false,
  lookupSourceFieldId: value.lookup?.sourceField?.id ?? null,
  lookupValueFieldId: value.lookup?.valueField?.id ?? null,
  rollupRelatedField: null,
  rollupField: null,
  aggregation: null,
  options:
    (value && isOptionType(value.type)
      ? (sortOptions(value.options ?? []) as UpsertedOption[])
      : undefined) ?? [],
  reverseName: (value.reverseDisplayName ?? '') as string,
  typeOptions: value.typeOptions ?? ({} as Partial<FieldTypeOptions>),
  autoRelationshipConfig: null,
});

const getSampleDisplayNameForType = (type: any, dataTypes: any) => {
  const isDataType =
    !baseDataTypes.includes(type) &&
    type !== FORMULA &&
    type !== LOOKUP &&
    type !== ROLLUP;
  if (isDataType) {
    const dt = dataTypes.getByName(type);
    return dt ? dt.display : type;
  } else if (type === LOOKUP) {
    return getText('data.typeInput.lookup.label');
  } else if (type === ROLLUP) {
    return getText('data.typeInput.rollup.label');
  } else if (type === FORMULA) {
    return getText('data.typeInput.formula.label');
  }

  return String(getText('data.types', type, 'label'))
    .split('(')[0]
    .trim();
};

type NewFieldFormProps = {
  dataType: DataType;
  onClose: () => void;
  dataTypes: DataTypes;
  projectName: string;
  surface: Surface;
  value?: DataField;
  canDelete?: boolean;
};

const NewFieldForm = ({
  dataType,
  onClose,
  dataTypes,
  projectName,
  surface,
  value,
  canDelete = false,
}: NewFieldFormProps) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showEditFieldTypeModal, setShowEditFieldTypeModal] =
    useState<boolean>(false);
  const [saveDataField] = useMutation(ADD_DATA_FIELD);
  const [upsertDataField] = useMutation(UPSERT_DATA_FIELD);
  const [saveDataLookup] = useMutation(ADD_DATA_LOOKUP);
  const [saveDataRollup] = useMutation(ADD_DATA_ROLLUP);

  const lookupFieldsEnabled = useHasFeatureFlag(LOOKUP_FIELDS);

  const [initialForm] = useState(() => getDefaultFormValues(value));
  type FormKeys = keyof typeof initialForm;

  const [formValues, setFormValues] = useState<
    typeof initialForm & { isDirty: any }
  >({
    ...cloneDeep(initialForm),
    isDirty: {},
  });
  const type = useMemo(() => formValues.type, [formValues.type]);

  const getFormValueSetter = useCallback(
    (key: FormKeys) => (arg: any | ((old: any) => any)) => {
      setFormValues((prevFormValue: any) => {
        const newValue =
          typeof arg === 'function' ? arg(prevFormValue[key]) : arg;

        const isDirty = !isEqual(newValue, initialForm[key]);
        return {
          ...prevFormValue,
          [key]: newValue,
          isDirty: { ...prevFormValue.isDirty, [key]: isDirty },
        };
      });
    },
    [initialForm],
  );
  const setIsMulti = useMemo(
    () => getFormValueSetter('isMulti'),
    [getFormValueSetter],
  );
  const setReverseName = useMemo(
    () => getFormValueSetter('reverseName'),
    [getFormValueSetter],
  );
  const setIsReverseMulti = useMemo(
    () => getFormValueSetter('isReverseMulti'),
    [getFormValueSetter],
  );
  const setAutoRelationshipConfig = useMemo(
    () => getFormValueSetter('autoRelationshipConfig'),
    [getFormValueSetter],
  );
  const setTypeOptions = useMemo(
    () => getFormValueSetter('typeOptions'),
    [getFormValueSetter],
  );
  const setType = useMemo(
    () => getFormValueSetter('type'),
    [getFormValueSetter],
  );
  const setDisplayName = useMemo(
    () => getFormValueSetter('displayName'),
    [getFormValueSetter],
  );
  const setOptions = useMemo(
    () => getFormValueSetter('options'),
    [getFormValueSetter],
  );
  const setUnique = useMemo(
    () => getFormValueSetter('unique'),
    [getFormValueSetter],
  );
  const setLookupSourceFieldId = useMemo(
    () => getFormValueSetter('lookupSourceFieldId'),
    [getFormValueSetter],
  );
  const setLookupValueFieldId = useMemo(
    () => getFormValueSetter('lookupValueFieldId'),
    [getFormValueSetter],
  );
  const setAggregation = useMemo(
    () => getFormValueSetter('aggregation'),
    [getFormValueSetter],
  );
  const setRollupField = useMemo(
    () => getFormValueSetter('rollupField'),
    [getFormValueSetter],
  );
  const setRollupRelatedField = useMemo(
    () => getFormValueSetter('rollupRelatedField'),
    [getFormValueSetter],
  );

  const resetForm = () => {
    setFormValues({ ...getDefaultFormValues(), isDirty: {} });
    onClose();
  };
  const isFormDirty = useMemo(() => {
    return Object.values(formValues.isDirty).some((v) => v);
  }, [formValues.isDirty]);

  const addDataField = useAddDataField();
  const updateDataField = useUpdateDataField();
  const errorAlert = useGraphQlErrorAlert();
  const [isLoading, setIsLoading] = useState(false);

  const [relationship, setRelationship] = useState<any>(
    getRelationshipFromMultiOpts(formValues.isMulti, formValues.isReverseMulti),
  );

  const [showBulkAddScreen, setShowBulkAddScreen] = useState(false);

  const isLookup = useMemo(
    () => type === LOOKUP || (value && value.lookup),
    [type, value],
  );
  const isRollup = useMemo(
    () => type === ROLLUP || (value && value.rollup),
    [type, value],
  );
  const [isUpdate] = useState<boolean>(!!value?.id);

  const getValidTypesToUpdate = useCallback(() => {
    if (!value) {
      return;
    }
    const isAirTable = value.source === AIRTABLE;
    if (isAirTable) {
      return [TEXT, PHONE_NUMBER];
    }
    const isFormula = !!value.typeOptions?.formula;
    if (isFormula) {
      return VALID_FORMULA_TYPES_TO_CONVERT;
    }
    if (VALID_TYPES_TO_CONVERT.includes(value.type)) {
      return VALID_TYPES_TO_CONVERT;
    }

    return;
  }, [value]);

  const {
    canChangeFieldDisplay,
    canChangeFieldOptions,
    canChangeFieldType,
    canChangeFieldTypeOptions,
    canChangeFieldOptionsColor,
    canDeleteField,
    canSetFieldAsPrimary,
    canChangeRelationship,
    canChangeFieldCanBeNegative,
  } = useMutableFieldProperties(dataType, value);

  const showFieldTypeOptions = useMemo(
    () =>
      (isUpdate && canChangeFieldTypeOptions) ||
      ((FIELDS_WITH_TYPE_OPTIONS.includes(type ?? '') || type === FORMULA) &&
        !isLookup &&
        !isRollup),
    [canChangeFieldTypeOptions, type, isLookup, isRollup, isUpdate],
  );

  const dispatch = useDispatch();
  const [updateDataType, { loading: updateLoading }] =
    useMutation(UPDATE_DATA_TYPE);
  const onAllowNegativeChange = useCallback(
    (value: boolean) =>
      setTypeOptions((typeOptions: any) => ({
        ...typeOptions,
        allowNegative: value,
      })),
    [setTypeOptions],
  );

  const setPrimaryField = useCallback(() => {
    if (!value?.id) {
      return;
    }
    updateDataType({
      variables: {
        projectName,
        id: dataType.id,
        primaryField: value.id,
      },
    }).then(({ data }) => {
      const updatedDataType = data.updateDataType;
      dispatch(
        updateDataTypeState(
          addRelatedFieldsToDataType(updatedDataType, dataTypes),
        ),
      );
    });
  }, [
    dataType.id,
    dataTypes,
    dispatch,
    projectName,
    updateDataType,
    value?.id,
  ]);

  const onTypeChange = useCallback(
    (newType) => {
      if (OBJECT_FORMATS.includes(newType)) {
        setType(OBJECT);
      } else {
        setType(newType);
      }

      if (
        !formValues.displayName ||
        getSampleDisplayNameForType(type, dataTypes) === formValues.displayName
      ) {
        setDisplayName(getSampleDisplayNameForType(newType, dataTypes));
      }
      const validTypeOptions = getValidTypeOptionsForFieldSource(
        OBJECT_FORMATS.includes(newType) ? OBJECT : newType,
        dataType.source.type,
      );
      setTypeOptions((prevTypeOptions: any) => {
        const newTypeOptions = {
          ...prevTypeOptions,
          format: OBJECT_FORMATS.includes(newType) ? newType : undefined,
          subFields: OBJECT_FORMATS.includes(newType)
            ? get(SUB_FIELD_CONFIG, [newType])
            : undefined,
        };
        Object.entries(validTypeOptions).forEach(([key, value]) => {
          if (!value) {
            delete newTypeOptions[key];
          }
        });
        if (newType === PHONE_NUMBER) {
          newTypeOptions.format = PHONE_NUMBER;
        }
        if (newType === FORMULA) {
          newTypeOptions.formula = '';
        }

        return newTypeOptions;
      });
    },
    [
      formValues.displayName,
      type,
      dataTypes,
      dataType.source.type,
      setTypeOptions,
      setType,
      setDisplayName,
    ],
  );

  const formulaIsValid = useMemo(() => {
    if (!formValues.typeOptions.formula) {
      return false;
    }

    return isFormulaValid(
      formValues.typeOptions.formula,
      dataType.fields,
      [...formValues.typeOptions.formula.matchAll(/{{([A-z0-9]+)}}/g)].map(
        (match) => match[1],
      ),
    ).valid;
  }, [dataType.fields, formValues.typeOptions.formula]);

  const optionsAreValid =
    formValues.options.slice(0, -1).every(isOptionValid) &&
    formValues.options.length > 1;

  const { isValid: isDisplayNameValid, validationMessage } =
    useFieldNameValidation(
      formValues.displayName,
      type as DataFieldType | null,
      dataType,
      dataTypes,
      value,
    );

  const isCustomRelationship =
    type &&
    !baseDataTypes.includes(type as any) &&
    type !== FORMULA &&
    type !== LOOKUP &&
    type !== OBJECT &&
    type !== ROLLUP;

  const relatedType = useMemo(
    () => (type && isCustomRelationship ? dataTypes.getByName(type) : null),
    [dataTypes, isCustomRelationship, type],
  );

  const {
    isValid: isReverseDisplayNameValid,
    validationMessage: reverseNameValidationMessage,
  } = useReverseFieldNameValidation(
    formValues.reverseName,
    relatedType!,
    relationship,
    dataType.fields,
    value?.id,
  );

  const requiresReverseName =
    isCustomRelationship && relationship && type !== FILE;

  const fieldCanBeNegative = type && NUMERIC_DATATYPES.includes(type);

  const fieldCanBeUnique =
    type &&
    !isCustomRelationship &&
    !NON_UNIQUE_DATA_TYPES.includes(type) &&
    type !== FORMULA &&
    type !== LOOKUP &&
    type !== OBJECT &&
    type !== ROLLUP;

  const [lookupIsValid, setLookupIsValid] = useState(false);
  const [rollupIsValid, setRollupIsValid] = useState(false);
  const areOptionsNeeded = useMemo(
    () => isOptionType(type) && (!value || isOptionType(value.type)),
    [type, value],
  );
  const isValid = useMemo(() => {
    if (!isFormDirty) {
      return false;
    }
    if (type === LOOKUP) {
      return isDisplayNameValid && lookupIsValid;
    }
    if (type === ROLLUP) {
      return (
        isDisplayNameValid && type && formValues.rollupField && rollupIsValid
      );
    }
    return (
      isDisplayNameValid &&
      type &&
      (!isCustomRelationship || relationship) &&
      (!areOptionsNeeded || optionsAreValid) &&
      (!formValues.typeOptions ||
        formValues.typeOptions.formula === undefined ||
        formulaIsValid) &&
      (!requiresReverseName || isReverseDisplayNameValid) &&
      (!formValues.autoRelationshipConfig ||
        ((formValues.autoRelationshipConfig as any).sourceFieldId &&
          (formValues.autoRelationshipConfig as any).lookupFieldId))
    );
  }, [
    isFormDirty,
    type,
    isDisplayNameValid,
    isCustomRelationship,
    relationship,
    areOptionsNeeded,
    optionsAreValid,
    formValues.typeOptions,
    formValues.autoRelationshipConfig,
    formValues.rollupField,
    formulaIsValid,
    requiresReverseName,
    isReverseDisplayNameValid,
    lookupIsValid,
    rollupIsValid,
  ]);

  const handleOnSave = async (confirmDataFieldTypeChange: boolean = false) => {
    setIsLoading(true);
    try {
      if (type === LOOKUP) {
        const { data } = await saveDataLookup({
          variables: {
            projectName: projectName,
            dataTypeId: dataType.id,
            display: formValues.displayName,
            sourceFieldId: formValues.lookupSourceFieldId,
            valueFieldId: formValues.lookupValueFieldId,
          },
        });
        if (data.addDataLookup) {
          addDataField({
            dataField: data.addDataLookup,
            dataTypeName: dataType.name,
          });
        }

        trackEvent(NEW_LOOKUP_FIELD_SAVED);
      } else if (type === ROLLUP) {
        const { data } = await saveDataRollup({
          variables: {
            projectName: projectName,
            dataTypeId: dataType.id,

            display: formValues.displayName,

            relatedField: formValues.rollupRelatedField,

            field: formValues.rollupField,
            aggregation: formValues.aggregation,
          },
        });
        if (data.addDataRollup) {
          addDataField({
            dataField: data.addDataRollup,
            dataTypeName: dataType.name,
          });
        }

        trackEvent(NEW_ROLLUP_FIELD_SAVED);
      } else {
        const newField = {
          display: formValues.displayName,

          type: type === FORMULA ? TEXT : type,

          relationship: isCustomRelationship ? relationship : undefined,

          reverseDisplay:
            type !== FILE ? formValues.reverseName : dataType.name,

          unique: fieldCanBeUnique && formValues.unique,
          options: cleanOptions(formValues.options),
          typeOptions: formValues.typeOptions,
          autoRelationshipConfig: formValues.autoRelationshipConfig,
        };

        if (!value) {
          trackEvent(NEW_DATA_FIELD_SAVED);

          const { data } = await saveDataField({
            variables: {
              projectName: projectName,
              dataTypeId: dataType.id,
              ...newField,
            },
          });
          if (data.addDataField) {
            addDataField({
              dataField: data.addDataField,
              dataTypeName: dataType.name,
            });
          }
        } else {
          trackEvent(DATA_FIELD_UPDATED);
          if (value.type !== newField.type) {
            //editing type
            if (!confirmDataFieldTypeChange) {
              setShowEditFieldTypeModal(true);
              return;
            }
            setShowEditFieldTypeModal(false);
          }
          const { data } = await upsertDataField({
            variables: {
              projectName: projectName,
              dataTypeId: dataType.id,
              id: value.id,
              ...newField,
            },
          });
          if (data.upsertDataField) {
            updateDataField({
              dataField: data.upsertDataField,
              dataTypeId: dataType.id,
            });
          }
        }
      }
      setIsLoading(false);
      resetForm();
    } catch (e) {
      errorAlert(getText('data.fields.error'), e);

      setIsLoading(false);
    }
  };

  if (value && showDeleteModal) {
    return (
      <DeleteFieldModal
        field={value}
        onClose={() => setShowDeleteModal(false)}
        onDeleteSuccess={onClose}
        projectName={projectName}
        dataTypeId={dataType.id}
      />
    );
  }
  if (showEditFieldTypeModal) {
    return (
      <ChangeFieldTypeWarningModal
        onClose={() => {
          setIsLoading(false);
          setShowEditFieldTypeModal(false);
        }}
        onConfirm={() => {
          setIsLoading(true);
          setShowEditFieldTypeModal(false);
          handleOnSave(true);
        }}
      />
    );
  }
  return (
    <RightPopoutMenu
      title={value ? value.display : getText('data.fields.new')}
      footer={
        <div className="flex items-center space-x-2">
          <Button
            small={true}
            data-testid="new-field-cancel"
            disabled={isLoading}
            onClick={resetForm}
            type={OUTLINE}
          >
            {getText('data.fields.cancel')}
          </Button>
          <Button
            small={true}
            data-testid="new-field-submit"
            disabled={!isValid || isLoading}
            onClick={() => handleOnSave()} //not same as conClick={handleOnSave} we don't want the event object
          >
            {isLoading ? (
              <Loader size={XS} />
            ) : (
              getText('data.fields', value ? 'save' : 'add')
            )}
          </Button>
        </div>
      }
      onClose={onClose}
      size={MD}
    >
      <div className="flex flex-col p-4">
        {dataType.source.type !== INTERNAL && !value && (
          <ErrorText className="mb-4 flex items-center">
            <IconAlertTriangle
              size={22}
              className="mr-4 flex-shrink-0 opacity-75"
            />
            <div
              className="flex flex-col"
              data-testid="new-field-data-source-warning"
            >
              <span>{getText('data.fields.externalSource.warning')}</span>
              <span className="mt-px text-xs text-gray-100">
                {getText('data.fields.externalSource', dataType.source.type)}
              </span>
            </div>
          </ErrorText>
        )}
        <FormField
          className="mb-2 text-black"
          data-testid="new-field-name"
          errorMessage={
            validationMessage && (
              <span className="text-white">{validationMessage}</span>
            )
          }
          label={getText('data.fields.label')}
          placeholder={getText('data.fields.placeholder')}
          tooltip={getText('data.fields.tooltip')}
          value={formValues.displayName}
          options={sampleFieldNameOptions}
          inputType="autocomplete"
          errorType="below-solid"
          onChange={({ target: { value } }: any) => setDisplayName(value)}
          size={LG}
          surface={surface}
          disabled={!!value && !canChangeFieldDisplay}
        />

        <Label surface={surface} className="mb-1 mt-4">
          {getText('data.typeInput.label')}
        </Label>
        <FieldTypeInput
          readOnly={value && !canChangeFieldType}
          dataTypes={dataTypes}
          lookupFieldsEnabled={lookupFieldsEnabled}
          objectTypesEnabled={true}
          onChange={onTypeChange}
          surface={surface}
          value={getValueForFieldTypeInput(type, formValues.typeOptions)}
          typeOptions={getValidTypesToUpdate()}
        />
        {showFieldTypeOptions && (
          <FieldTypeOptionsEditor
            dataType={dataType}
            className="my-2"
            fieldType={type === OBJECT ? formValues.typeOptions?.format : type}
            fieldSource={value?.source}
            onChange={setTypeOptions}
            surface={surface}
            value={formValues.typeOptions}
            readOnly={isUpdate && !canChangeFieldTypeOptions}
          />
        )}
        {isOptionType(type) &&
          (value ? (
            areOptionsNeeded && (
              <div className="flex w-full flex-col px-6 py-3">
                <FieldOptionsInput
                  dataTypeId={dataType.id}
                  projectName={projectName}
                  readOnly={!canChangeFieldOptions}
                  readOnlyColor={!canChangeFieldOptionsColor}
                  field={value}
                  options={formValues.options}
                  setOptions={setOptions}
                  showBulkAddScreen={showBulkAddScreen}
                  setShowBulkAddScreen={setShowBulkAddScreen}
                  surface={DARK}
                />
                {!showBulkAddScreen && canChangeFieldOptions && (
                  <button
                    className="mr-auto mt-4 text-left text-sm text-gray-500 hover:underline"
                    onClick={() => setShowBulkAddScreen(true)}
                  >
                    {getText('data.fields.bulkAdd.title')}
                  </button>
                )}
              </div>
            )
          ) : (
            <OptionEditor
              className="my-2"
              options={formValues.options}
              setOptions={setOptions}
              surface={surface}
            />
          ))}
        {isCustomRelationship && (!value || canChangeRelationship) && (
          <RelationshipEditor
            allowAutoRelationships={!isUpdate} //TODO @Josep. allow updating autorelationships
            autoRelationshipConfig={formValues.autoRelationshipConfig}
            dataType={dataType}
            dataTypes={dataTypes}
            displayName={formValues.displayName}
            isMulti={formValues.isMulti}
            isReverseMulti={formValues.isReverseMulti}
            readOnly={false}
            relationship={relationship}
            relatedType={relatedType!}
            reverseName={formValues.reverseName ?? ''}
            requiresReverseName={requiresReverseName}
            reverseNameValidationMessage={reverseNameValidationMessage}
            setAutoRelationshipConfig={setAutoRelationshipConfig}
            setIsMulti={setIsMulti}
            setIsReverseMulti={setIsReverseMulti}
            setRelationship={setRelationship}
            setReverseName={setReverseName}
            surface={surface}
            type={type}
          />
        )}
        {fieldCanBeNegative && (
          <>
            <div className="my-4 flex items-center">
              <Label surface={surface} m={0}>
                {getText('data.allowNegative.label')}
              </Label>
              <HelpTooltip
                className={classNames('ml-2 hover:text-gray-500', {
                  'text-gray-800': surface === LIGHT,
                  'text-gray-200': surface === DARK,
                })}
                placement="right"
              >
                <p>{getText('data.allowNegative.tooltip')}</p>
              </HelpTooltip>
              <Switch
                size={SM}
                data-testid="field-allow-negative-switch"
                className="ml-auto"
                value={allowNegative({
                  typeOptions: formValues.typeOptions,
                } as DataField)}
                onChange={onAllowNegativeChange}
                disabled={isUpdate && !canChangeFieldCanBeNegative}
              />
            </div>
          </>
        )}
        {!value && fieldCanBeUnique && (
          <>
            <div className="my-4 flex items-center">
              <Label surface={surface} m={0}>
                {getText('data.uniqueInput.label')}
              </Label>
              <HelpTooltip
                className={classNames('ml-2 hover:text-gray-500', {
                  'text-gray-800': surface === LIGHT,
                  'text-gray-200': surface === DARK,
                })}
                placement="right"
              >
                <p>{getText('data.uniqueInput.tooltip')}</p>
              </HelpTooltip>
              <Switch
                size={SM}
                data-testid="field-unique-switch"
                className="ml-auto"
                value={formValues.unique}
                onChange={setUnique}
              />
            </div>
          </>
        )}
        {isLookup && (
          <LookupEditor
            dataType={dataType}
            dataTypes={dataTypes}
            sourceFieldId={formValues.lookupSourceFieldId}
            valueFieldId={formValues.lookupValueFieldId}
            readOnly={!!value}
            setSourceFieldId={setLookupSourceFieldId}
            setValueFieldId={setLookupValueFieldId}
            onValidChange={setLookupIsValid}
            surface={DARK}
          />
        )}
        {isRollup && (
          <RollupEditor
            aggregation={formValues.aggregation}
            dataType={dataType}
            dataTypes={dataTypes}
            rollupField={formValues.rollupField}
            rollupRelatedField={formValues.rollupRelatedField}
            setAggregation={setAggregation}
            setRollupField={setRollupField}
            setRollupRelatedField={setRollupRelatedField}
            onValidChange={setRollupIsValid}
            surface={DARK}
          />
        )}
        {value && <FieldVariableNameDetails field={value} />}
        {((canSetFieldAsPrimary && isUpdate) ||
          (canDelete && canDeleteField)) && <hr className="mx-4 my-4" />}
        {canSetFieldAsPrimary && isUpdate && (
          <button
            className="flex items-center rounded-lg px-3 py-3 text-left text-sm text-white hover:bg-gray-400 disabled:opacity-50"
            disabled={updateLoading}
            onClick={setPrimaryField}
          >
            <IconBookmark size={16} className="mr-4 opacity-75" />
            <span className="w-72">
              {getText('data.fields.primaryField.set')}
            </span>
          </button>
        )}
        {canDelete && canDeleteField && (
          <button
            className="flex items-center rounded-lg px-3 py-3 text-left text-sm text-white hover:bg-red-400"
            onClick={() => setShowDeleteModal(true)}
          >
            <IconTrash size={16} className="mr-4 opacity-75" />
            <span className="w-72">{getText('data.fields.delete.button')}</span>
          </button>
        )}
      </div>
    </RightPopoutMenu>
  );
};

export default NewFieldForm;
